export default {
  getEntity({ dispatch, state }, lookup, eager = false) {
    return new Promise((resolved) => {
      const stateData = state[lookup] || [];
      if (stateData.length > 0 || eager) {
        resolved(stateData);
      } else {
        dispatch('app/get', { url: lookup }, { root: true }).then((data) => {
          resolved(data);
          state[lookup] = data;
        });
      }
    });
  },
};
