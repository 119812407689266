import Vue from 'vue';
import { PROFILE_KEY } from '@/libs/acl/config';

Vue.directive('permission', {
  inserted: function (el, binding, vnode) {
    const bindPermission = binding.value;
    const profile = JSON.parse(window.localStorage.getItem(PROFILE_KEY) || '{}');
    if (profile.isAdmin) return;
    if (bindPermission && profile.permissions && profile.permissions.indexOf(bindPermission) === -1) {
      if (vnode.elm.parentElement) vnode.elm.parentElement.removeChild(vnode.elm);
    }
  },
});

// Vue.directive('navigation-permission', {
//   inserted: function (el, binding, vnode) {
//     const bindPermission = binding.value;
//     const profile = JSON.parse(window.localStorage.getItem(PROFILE_KEY) || '{}');
//     if (profile.isAdmin) return;
//     if (bindPermission && bindPermission.children && bindPermission.children.map(i => i.permission).some(p => profile.permissions.indexOf(p) === -1)) {
//       if (vnode.elm.parentElement) vnode.elm.parentElement.removeChild(vnode.elm);
//     }
//   },
// });
