//  viewAssets, addAssets, editAssets, deleteAssets,
// viewQuotations, addQuotations, editQuotations, deleteQuotations,
// viewSalesOrders, addSalesOrders, editSalesOrders, deleteSalesOrders,
// addSalesWithRemainder, modifySalesPrice, viewReturnSalesTransactions, addReturnSalesTransactions, editReturnSalesTransactions, deleteReturnSalesTransactions, viewPurchOrders, addPurchOrders, editPurchOrders, deletePurchOrders, viewPurchTransactions, addPurchTransactions, editPurchTransactions, deletePurchTransactions, viewReturnPurchTransactions, addReturnPurchTransactions, editReturnPurchTransactions, deleteReturnPurchTransactions, viewAdditionOrders, addAdditionOrders, editAdditionOrders, deleteAdditionOrders ]
export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Payments',
    route: 'payments',
    icon: 'DollarSignIcon',
  },
  {
    title: 'calculations',
    route: 'calculations',
    icon: 'GridIcon',
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'perssonalAccount',
        route: 'perssonalAccount',
      },
      {
        title: 'changePassword',
        route: 'change-password',
      },
    ],
  },
];
