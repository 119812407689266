import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
// import { domain, baseReportURL } from '@/libs/acl/config'

Vue.mixin({
  data() {
    return {
      perPageOptions: [15, 25, 50, 100, 'الكل'],
      // baseUrl: domain,
      // baseReportURL: baseReportURL,
      currentUrl: window.location.href,
      // domain: domain
    };
  },
  updated: function () {
    if (this.currentBranch) {
      this.fetch();
    }
  },
  computed: {
    ...mapGetters({
      currentLang: 'appConfig/lang',
      isRight: 'appConfig/isRTL',
      // branchId: 'app/branchId',
      // branches: 'app/branches',
      // currentBranch: 'app/currentBranch',
      // company: 'app/currentCompany',
      // profile: 'auth/profile',
      currentBreakPoint: 'app/currentBreakPoint',
    }),
    today() {
      return moment().format('YYYY-MM-DD');
    },
    updatedToday() {
      return moment().format('YYYY-MM-DDTHH:mm:ss');
    },
    time() {
      const time = moment().format('HH:mm:ss A');
      return moment(time, ['h:mm:ss A']).format('HH:mm:ss');
    },
  },
  methods: {
    fetch() {
      if (this.currentBranch.isMaster) {
      fetch(this.currentUrl).then(() => {
          const buttons = document.querySelectorAll('[data-action-type]');
          buttons.forEach(el => {
              if (el.getAttribute('data-action-type') === 'delete' || el.getAttribute('data-action-type') === 'edit') {
                el.setAttribute('hidden', true);
              } else if (el.getAttribute('data-action-type') === 'preview') {
                el.removeAttribute('hidden');
              } else {
                el.disabled = true;
              }
            });
  }).catch(() => {

  })
}
},
    getTime(time) {
      time = time || moment().format('HH:mm:ss A');
      return moment(time, ['h:mm:ss A']).format('HH:mm:ss');
    },
    getDate(date, isTime = false) {
      const format = isTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
      date = date || new Date();
      return moment(date).format(format);
    },
    fromUTC(date) {
      const utcMoment = moment.utc(date);
      // Convert to local time
      const localMoment = utcMoment.local();
      // Format the local date as a string (optional)
      return localMoment.format('YYYY-MM-DD HH:mm');
    },
    fraction(number) {
      number = number || 0;
      return parseFloat(number).toFixed(2);
    },
    a2hex(str) {
      // const strLength = str.length
      return Buffer.from(str, 'utf8').toString('hex');
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) && 0xFF;
      return buf;
    },
    convertTextToJson(text) {
      const lines = text.split('\n');
      const json = {};
      lines.forEach(line => {
        const [key, value] = line.split(':');
        const trimmedKey = key.trim();
        const trimmedValue = value.trim();
        json[trimmedKey] = trimmedValue;
      });
      return JSON.stringify(json, null, 2);
    },
    d2h(d) { return d > 16 ? (+d).toString(16) : `0${(+d).toString(16)}` },
    hex_to_ascii(str1) {
      const hex = str1.toString();
      this.str = '';
      for (let n = 0; n < hex.length; n += 2) {
        this.str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
      }
      return this.str;
    },
    truncateNum(number) {
      return number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },
    getFilterObj(obj) {
      return Object.entries(obj).reduce((query, item) => {
        if ([null, ''].indexOf(item[1]) > -1) return query;
        if (query) query += '&';
        query += `${item[0]}=${item[1]}`;
        return query;
      }, '');
    },
    orderQuery(sortBy, sortDesc) {
      if (sortBy) return `${sortBy} ${sortDesc ? 'DESC' : 'ASC'}`;
      return '';
    },
    ...mapActions({
      get: 'app/get',
      getAll: 'app/getAll',
      create: 'app/create',
      update: 'app/update',
      delete: 'app/delete',
    }),
    doneAlert({ type, title, text }) {
      type = type || 'success';
      this.$swal({
        icon: type,
        title: title || this.$t(type),
        text: text,
        timer: 2000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      });
    },
    // $can(permission) {
    //   if (this.profile.isAdmin) return this.profile.isAdmin;
    //   return !this.profile.permissions || this.profile.permissions.indexOf(permission) > -1;
    // },
    confirmAction(data, callbackFn) {
      const {
        title, text, type, confirmText, cancelText
      } = data;
      this.$swal({
        title: title || this.$t('Are you sure?'),
        text: text,
        icon: type || 'warning',
        showCancelButton: true,
        confirmButtonText: confirmText || this.$t('Yes, delete it!'),
        cancelButtonText: cancelText || this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (callbackFn) callbackFn();
        }
      });
    },
    confirmActivate(data, callbackFn) {
      const {
        title, text, type, confirmText, cancelText
      } = data;
      this.$swal({
        title: title || this.$t('Are you sure?'),
        text: text,
        icon: type || 'warning',
        showCancelButton: true,
        confirmButtonText: confirmText || this.$t('Yes!'),
        cancelButtonText: cancelText || this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (callbackFn) callbackFn();
        }
      });
    },
  },
});
