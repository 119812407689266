import Router from '@/router'
import { TOKEN_KEY, PROFILE_KEY } from '@/libs/acl/config'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem(TOKEN_KEY) || null,
    profile: JSON.parse(localStorage.getItem(PROFILE_KEY))
  },
  getters: {
    token: state => state.token || null,
    profile: state => state.profile || {},
    // branchId: state => state.branchId || null,
  },
  mutations: {
    setToken(state, token) {
      localStorage.setItem(TOKEN_KEY, token)
      state.token = token
    },
    setProfile(state, profile) {
      localStorage.setItem(PROFILE_KEY, JSON.stringify(profile))
      state.profile = profile
    },
  },
  actions: {
    login({ commit, dispatch }, { email, password }) {
      dispatch(
        'app/create',
        { url: 'Clients/login', data: { email: email, password: password } },
        { root: true },
      ).then(data => {
        commit('setToken', data.token)
        commit('setProfile', data)
        dispatch(
          'app/get',
          { url: 'Clients/status' },
          { root: true },
        ).then(res => {
          if (res === false) {
            Router.push({
              name: 'activate',
              query: { key: data.token, resend: true },
              params: {
                activation: data
              }
            })
          } else {
          Router.push('/')
          }
        })
      })
    },
    AdminLogin({ commit, dispatch }, { email, password }) {
      dispatch(
        'app/create',
        { url: 'User/login', data: { userName: email, password: password } },
        { root: true },
      ).then(data => {
        commit('setToken', data.token)
        commit('setProfile', data)
        Router.push('/admin-home')
      }).catch(({ data }) => {
        this.doneAlert({ text: this.$t(data.detail), type: 'error' });
        Router.push('/admin/login')
      });
    },
    logout({ commit }) {
      window.localStorage.removeItem(TOKEN_KEY)
      window.localStorage.removeItem(PROFILE_KEY)
      commit('setToken', '')
      commit('setProfile', {})
      Router.push('/login')
    },
    changePassword() {
      Router.push('/change-password')
    },
  },
}
