<template>
  <b-form-group :label="$t($attrs['label-text'])">
    <ValidationProvider
      ref="fieldValidator"
      v-slot="{ errors }"
      :rules="rules || ''"
      :vid="$attrs.name || ''"
      mode="eager"
    >
      <div class="q-mb-md">
        <b-input-group>
          <b-form-input
            :value="inputValue"
            type="text"
            placeholder="YYYY-MM-DD"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-if="!disabled"
              ref="picker"
              :value="inputValue"
              button-only
              :right="isRight"
              size="sm"
              :locale="isRight ? 'ar-EG' : 'en-us'"
              variant="primary"
              selected-variant="primary"
              aria-controls="example-input"
              @context="onContext"
              @input="
                (v) => {
                  onContext({ selectedYMD: v });
                }
              "
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
        <small
          :class="{ 'd-none': !$attrs['label-text'] }"
          class="text-danger"
          >{{ errors[0] }}</small
        >
      </div>
    </ValidationProvider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    dir: {
      type: String,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: '',
    },
  },
  emits: ['update:value'],
  data() {
    return {
      inputValue: '',
    };
  },
  mounted() {
    this.inputValue = this.getDate(this.value);
  },
  watch: {
    value(newVale) {
      this.inputValue = this.getDate(newVale);
    },
  },
  methods: {
    onContext(ctx) {
      this.inputValue = ctx.selectedYMD;
      this.$emit('update:value', this.inputValue);
      this.$emit('change', this.inputValue);
    },
  },
};
</script>

<style></style>
