import { getUserData } from '@/auth/utils'
// import ability from './ability';

export const canNavigate = (to) => {
  const profile = getUserData();
  const permissions = profile.permissions || [];
//   return to.matched.some((route) => ability.can(route.meta.action || 'read', route.meta.resource));
  return profile.isAdmin || !to.meta.permission || permissions.indexOf(to.meta.permission) !== -1;
};

export const _ = undefined;
