import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils';
import { canNavigate } from '@/libs/acl/routeProtection';

const profile = JSON.parse(window.localStorage.getItem('@USER_PROFILE') || '{}');
const prodctionUrl = document.location.host.indexOf('/admin');

Vue.use(VueRouter);

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/pages/Dashboard/Edit.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payments',
      name: 'payments',
      component: () => import('@/pages/Payments/list.vue'),
      meta: {
        pageTitle: 'Payments',
        breadcrumb: [
          {
            text: 'Payments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/perssonalAccount',
      name: 'perssonalAccount',
      component: () => import('@/pages/Settings/profile/Edit.vue'),
      meta: {
        pageTitle: 'perssonalAccount',
        breadcrumb: [
          {
            text: 'perssonalAccount',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calculations',
      name: 'calculations',
      component: () => import('@/pages/Calculations/Edit.vue'),
      // meta: { layout: 'full' },
      meta: {
        pageTitle: 'calculations',
        breadcrumb: [
          {
            text: 'calculations',
            active: true,
          },
        ],
      },
    },
    {
      path: '/start-calc/:id',
      name: 'start-calc',
      component: () => import('@/pages/Calculations/Edit.vue'),
      meta: { layout: 'full' }
    },
    {
      path: '/activate',
      name: 'activate',
      component: () => import('@/pages/validateEmail/Edit.vue'),
      meta: { layout: 'full' },
      props: true
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/pages/Settings/ChangePassword/Edit.vue'),
      meta: {
        pageTitle: 'changePassword',
        breadcrumb: [{ text: 'changePassword', active: true }],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/UserAccount/Login.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/pages/UserAccount/register.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: { layout: 'full' },
    },
    //  ---------------------- admin route -------------------------- \\
    {
      path: '/admin/login',
      name: 'admin-login',
      component: () => import('@/pages/admin/login/login.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/admin-home',
      name: 'admin-home',
      component: () => import('@/pages/admin/home/Edit.vue'),
      meta: {
        pageTitle: 'admin-home',
        breadcrumb: [
          {
            text: 'admin-home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/plans',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'plans',
          component: () => import('@/pages/admin/plans/List.vue'),
          meta: {
            pageTitle: 'plans',
            breadcrumb: [{ text: 'plans', active: true }],
          },
        },
        {
          path: 'new',
          name: 'plan-new',
          component: () => import('@/pages/admin/plans/Edit.vue'),
          meta: {
            pageTitle: 'plan-new',
            breadcrumb: [
              { text: 'plans', active: true },
            ],
          },
        },
        {
          path: 'edit/:id',
          name: 'plan-edit',
          props: true,
          component: () => import('@/pages/admin/plans/Edit.vue'),
          meta: {
            pageTitle: 'plan-edit',
            breadcrumb: [
              { text: 'plans', active: true },
            ],
          },
        },
      ],
    },
    {
      path: '/admin/discount-code',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'discount-code',
          component: () => import('@/pages/admin/discountCode/List.vue'),
          meta: {
            pageTitle: 'discount-code',
            breadcrumb: [{ text: 'discount-code', active: true }],
          },
        },
        {
          path: 'new',
          name: 'discountCode-new',
          component: () => import('@/pages/admin/discountCode/Edit.vue'),
          meta: {
            pageTitle: 'discountCode-new',
            breadcrumb: [
              { text: 'discount-code', active: true },
            ],
          },
        },
        {
          path: 'edit/:id',
          name: 'discountCode-edit',
          props: true,
          component: () => import('@/pages/admin/discountCode/Edit.vue'),
          meta: {
            pageTitle: 'discountCode-edit',
            breadcrumb: [
              { text: 'discount-code', active: true },
            ],
          },
        },
      ],
    },
    {
      path: '/admin/pricing-policy',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'pricing-policy',
          component: () => import('@/pages/admin/pricingPolicy/List.vue'),
          meta: {
            pageTitle: 'pricing-policy',
            breadcrumb: [{ text: 'pricing-policy', active: true }],
          },
        },
        {
          path: 'new',
          name: 'pricingPolicy-new',
          component: () => import('@/pages/admin/pricingPolicy/Edit.vue'),
          meta: {
            pageTitle: 'pricingPolicy-new',
            breadcrumb: [
              { text: 'pricing-policy', active: true },
            ],
          },
        },
        {
          path: 'edit/:id',
          name: 'pricingPolicy-edit',
          props: true,
          component: () => import('@/pages/admin/pricingPolicy/Edit.vue'),
          meta: {
            pageTitle: 'pricingPolicy-edit',
            breadcrumb: [
              { text: 'pricing-policy', active: true },
            ],
          },
        },
      ],
    },
    {
      path: '/admin/current-users',
      name: 'current-users',
      component: () => import('@/pages/admin/currentUsers/List.vue'),
      meta: {
        pageTitle: 'current-users',
        breadcrumb: [
          {
            text: 'current-users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/notActive-users',
      name: 'notActive-users',
      component: () => import('@/pages/admin/NotActiveClients/List.vue'),
      meta: {
        pageTitle: 'notActive-users',
        breadcrumb: [
          {
            text: 'notActive-users',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/admin/history',
    //   name: 'history',
    //   component: () => import('@/pages/admin/HistoryLog/List.vue'),
    //   meta: {
    //     pageTitle: 'history',
    //     breadcrumb: [{ text: 'history', active: true }],
    //     permission: '',
    //   },
    // },
    {
      path: '/admin/fiscalYear',
      name: 'fiscalYear',
      component: () => import('@/pages/admin/FiscalYear/Edit.vue'),
      meta: {
        pageTitle: 'fiscalYear',
        breadcrumb: [{ text: 'fiscalYear', active: true }],
        permission: '',
      },
    },
    {
      path: '/admin/register',
      name: 'adminRegister',
      component: () => import('@/pages/admin/register/Edit.vue'),
      meta: {
        pageTitle: 'adminRegester',
        breadcrumb: [{ text: 'adminRegester', active: true }],
        permission: '',
      },
    },
    {
      path: '/admin/upgradePlan',
      name: 'upgradePlan',
      component: () => import('@/pages/admin/UpgradePlan/Edit.vue'),
      meta: {
        pageTitle: 'upgradePlan',
        breadcrumb: [{ text: 'upgradePlan', active: true }],
        permission: '',
      },
    },
    { path: '*', redirect: 'error-404' },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (prodctionUrl !== -1 && !isLoggedIn && to.name !== 'admin-login') return next({ name: 'admin-login' });
  if (profile.isAdmin && to.name === 'home') return next({ name: 'admin-home' });
  if (profile.isAdmin === undefined && to.name === 'admin-home') return next({ name: 'home' });
  // Redirect to login if not logged in
  if (!isLoggedIn && to.name !== 'register' && to.name !== 'login' && to.name !== 'activate' && to.name !== 'start-calc' && to.name !== 'admin-login') return next({ name: 'login' });
  if (!canNavigate(to)) {
    // If logged in => not authorized
    return next({ name: 'error-404' });
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
