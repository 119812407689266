import Vue from 'vue';

// Vue.directive('fraction', {
//   bind: function (el, binding, vnode) {
//   },
// });
Vue.directive('silent-search', {
  inserted: function (el, binding, vnode) {
    let oldVal = '';
    el.addEventListener('keyup', (event) => {
      oldVal = event.target.value;
      const { keydown, change } = vnode.componentInstance.$listeners;
      if (event.keyCode !== 13) {
        setTimeout(() => {
          const keyboardEvent = new KeyboardEvent('keydown', {
            altKey: false,
            bubbles: false,
            code: 'Enter',
            ctrlKey: false,
            charCode: 13,
            key: 'Enter',
          });
          if (oldVal === vnode.componentInstance.value) {
            if (keydown) keydown(keyboardEvent);
            else if (change) keydown(keyboardEvent);
          }
        }, 1000);
      }
    });
  },
});

Vue.directive('manage-value', {
  update: function (el, binding, vnode) {
    const fieldComponent = binding.value;
    const { componentInstance } = vnode;
    if (!fieldComponent.isDirty && fieldComponent.handelInput) fieldComponent.handelInput(componentInstance.value, false);
  }
});
